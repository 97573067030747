import shoe1 from "../images/shoe1.jpg";
import shoe2 from "../images/shoe2.jpg";
import shoe3 from "../images/shoe3.jpg";
import shoe4 from "../images/shoe4.jpg";
import shoe5 from "../images/shoe5.jpg";
import shoe6 from "../images/shoe6.jpg";
import shoe7 from "../images/shoe7.jpg";
import shoe8 from "../images/shoe8.jpg";
import shoe9 from "../images/shoe9.jpg";
import shoe10 from "../images/shoe10.jpg";
import shoe11 from "../images/shoe11.jpg";
import shoe12 from "../images/shoe12.jpg";
import shirt1 from "../images/t-shirt1.jpg";
import shirt2 from "../images/t-shirt2.jpg";
import shirt3 from "../images/t-shirt3.jpg";
import shirt4 from "../images/t-shirt4.jpg";
import shirt5 from "../images/t-shirt5.jpg";
import shirt6 from "../images/t-shirt6.jpg";
import shirt7 from "../images/t-shirt7.jpg";
import shirt8 from "../images/t-shirt8.jpg";
import hoodie1 from "../images/hoodie1.jpg";
import hoodie2 from "../images/hoodie2.jpg";
import hoodie3 from "../images/hoodie3.jpg";
import hoodie4 from "../images/hoodie4.jpg";
import hoodie5 from "../images/hoodie5.jpg";
import hoodie6 from "../images/hoodie6.jpg";
import hoodie7 from "../images/hoodie7.jpg";
import hoodie8 from "../images/hoodie8.jpg";
import dress1 from "../images/dress1.jpg";
import dress2 from "../images/dress2.jpg";
import dress3 from "../images/dress3.jpg";
import dress4 from "../images/dress4.jpg";
import dress5 from "../images/dress5.jpg";
import dress6 from "../images/dress6.jpg";
import dress7 from "../images/dress7.jpg";
import dress8 from "../images/dress8.jpg";
import jeans1 from "../images/jeans1.jpg";
import jeans2 from "../images/jeans2.jpg";
import jeans3 from "../images/jeans3.jpg";
import jeans4 from "../images/jeans4.jpg";
import jeans5 from "../images/jeans5.jpg";
import jeans6 from "../images/jeans6.jpg";
import jeans7 from "../images/jeans7.jpg";
import jeans8 from "../images/jeans8.jpg";
import jacket1 from "../images/jacket1.jpg";
import jacket2 from "../images/jacket2.jpg";
import jacket3 from "../images/jacket3.jpg";
import jacket4 from "../images/jacket4.jpg";
import jacket5 from "../images/jacket5.jpg";
import jacket6 from "../images/jacket6.jpg";
import jacket7 from "../images/jacket7.jpg";
import jacket8 from "../images/jacket8.jpg";
import bag1 from "../images/bag1.jpg";
import bag2 from "../images/bag2.jpg";
import bag3 from "../images/bag3.jpg";
import bag4 from "../images/bag4.jpg";
import bag5 from "../images/bag5.jpg";
import bag6 from "../images/bag6.jpg";
import bag7 from "../images/bag7.jpg";
import bag8 from "../images/bag8.jpg";
import suit1 from "../images/suit1.jpg";
import suit2 from "../images/suit2.jpg";
import suit3 from "../images/suit3.jpg";
import suit4 from "../images/suit4.jpg";
import suit5 from "../images/suit5.jpg";
import suit6 from "../images/suit6.jpg";
import suit7 from "../images/suit7.jpg";
import suit8 from "../images/suit8.jpg";

export const sliderData = [
  {
    id: "0",
    img: shoe1,
    text: "Summers SALE up to 50% OFF what are you wating for",
  },
  {
    id: "1",
    img: shoe2,
    text: "AUTUMN is coming, choose what suits you THE BEST",
  },
  {
    id: "2",
    img: shoe3,
    text: "Make your feet as comfortable as walking on the beach",
  },
  {
    id: "3",
    img: shoe4,
    text: "Choose between basketball and fashion or choose both",
  },
];

export const storeData = [
  {
    id: "1",
    img: shirt1,
    name: "Casual T-Shirt 1",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "T-Shirts",
    size: ["XL", "L", "M", "S"],
    color: ["black", "gray", "green"],
    gender: "male",
    price: 45,
  },
  {
    id: "2",
    img: shirt2,
    name: "Casual T-Shrit 2",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "T-Shirts",
    size: ["M", "L", "XL"],
    color: ["black", "red", "brown"],
    gender: "male",
    price: 55,
  },
  {
    id: "3",
    img: shirt3,
    name: "Casual T-Shrit 3",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "T-Shirts",
    size: ["L", "XL"],
    color: ["brown", "gray", "yellow"],
    gender: "female",
    price: 75,
  },
  {
    id: "4",
    img: shirt4,
    name: "Casual T-Shrit 4",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "T-Shirts",
    size: ["M", "L", "XL"],
    color: ["gray", "orange", "blue"],
    gender: "female",
    price: 25,
  },
  {
    id: "5",
    img: shirt5,
    name: "Casual T-Shrit 5",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "T-Shirts",
    size: ["M", "L", "XL"],
    color: ["black", "brown", "blue"],
    gender: "female",
    price: 15,
  },
  {
    id: "6",
    img: shirt6,
    name: "Casual T-Shrit 6",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "T-Shirts",
    size: ["M", "L", "XL"],
    color: ["gray", "purple", "yellow"],
    gender: "female",
    price: 25,
  },
  {
    id: "7",
    img: shirt7,
    name: "Casual T-Shrit 7",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "T-Shirts",
    size: ["M", "L", "XL"],
    color: ["black", "orange", "blue"],
    gender: "male",
    price: 25,
  },
  {
    id: "8",
    img: shirt8,
    name: "Casual T-Shrit 8",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "T-Shirts",
    size: ["M", "XL"],
    color: ["gray", "red", "blue"],
    gender: "male",
    price: 35,
  },

  {
    id: "9",
    img: hoodie1,
    name: "Casual Hoodie 1",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Hoodies",
    size: ["S", "L", "XL"],
    color: ["blue", "red", "green"],
    gender: "female",
    price: 85,
  },
  {
    id: "10",
    img: hoodie2,
    name: "Casual Hoodie 2",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Hoodies",
    size: ["S", "L", "XL"],
    color: ["gray", "purple", "black"],
    gender: "female",
    price: 95,
  },
  {
    id: "11",
    img: hoodie3,
    name: "Casual Hoodie 3",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Hoodies",
    size: ["S", "M", "XL"],
    color: ["red", "blue", "black"],
    gender: "male",
    price: 95,
  },
  {
    id: "12",
    img: hoodie4,
    name: "Casual Hoodie 4",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Hoodies",
    size: ["S", "XL"],
    color: ["gray", "blue", "red"],
    gender: "male",
    price: 125,
  },
  {
    id: "13",
    img: hoodie5,
    name: "Casual Hoodie 5",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Hoodies",
    size: ["S", "XL"],
    color: ["gray", "blue", "red"],
    gender: "female",
    price: 250,
  },
  {
    id: "14",
    img: hoodie6,
    name: "Casual Hoodie 6",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Hoodies",
    size: ["S", "XL"],
    color: ["gray", "yellow", "blue"],
    gender: "male",
    price: 125,
  },
  {
    id: "15",
    img: hoodie7,
    name: "Casual Hoodie 7",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Hoodies",
    size: ["S", "M", "L", "XL"],
    color: ["black", "blue"],
    gender: "male",
    price: 450,
  },
  {
    id: "16",
    img: hoodie8,
    name: "Casual Hoodie 8",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Hoodies",
    size: ["S", "M", "L", "XL"],
    color: ["green", "yellow", "red"],
    gender: "female",
    price: 355,
  },

  {
    id: "17",
    img: shoe5,
    name: "Casual Shoes 1",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Shoes",
    size: ["40", "42", "44", "46"],
    color: ["black", "gray"],
    gender: "male",
    price: 200,
  },
  {
    id: "18",
    img: shoe6,
    name: "Casual Shoes 2",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Shoes",
    size: ["40", "42", "44", "46"],
    color: ["brown", "gray"],
    gender: "female",
    price: 250,
  },
  {
    id: "19",
    img: shoe7,
    name: "Casual Shoes 3",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Shoes",
    size: ["36", "38", "40", "42"],
    color: ["black", "gray", "blue"],
    gender: "female",
    price: 685,
  },
  {
    id: "20",
    img: shoe8,
    name: "Casual Shoes 4",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Shoes",
    size: ["36", "38", "40", "42"],
    color: ["black", "gray"],
    gender: "male",
    price: 255,
  },
  {
    id: "21",
    img: shoe9,
    name: "Casual Shoes 5",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Shoes",
    size: ["36", "38", "40", "42"],
    color: ["purple", "gray", "black"],
    gender: "male",
    price: 355,
  },
  {
    id: "22",
    img: shoe10,
    name: "Casual Shoes 6",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Shoes",
    size: ["36", "38", "40", "42"],
    color: ["purple", "gray", "black"],
    gender: "male",
    price: 200,
  },
  {
    id: "23",
    img: shoe11,
    name: "Casual Shoes 7",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Shoes",
    size: ["36", "38", "40", "42"],
    color: ["purple", "green", "black"],
    gender: "male",
    price: 100,
  },
  {
    id: "24",
    img: shoe12,
    name: "Casual Shoes 8",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Shoes",
    size: ["36", "38", "40", "42"],
    color: ["purple", "green", "black"],
    gender: "male",
    price: 135,
  },
  {
    id: "25",
    img: dress1,
    name: "Casual Dress 1",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Dresses",
    size: ["L", "XL"],
    color: ["purple", "blue", "gray"],
    gender: "female",
    price: 355,
  },
  {
    id: "26",
    img: dress2,
    name: "Casual Dress 2",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Dresses",
    size: ["S", "M", "XL"],
    color: ["black", "gray", "green"],
    gender: "female",
    price: 255,
  },
  {
    id: "27",
    img: dress3,
    name: "Casual Dress 3",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Dresses",
    size: ["S", "M", "XL"],
    color: ["purple", "blue", "red"],
    gender: "female",
    price: 65,
  },
  {
    id: "28",
    img: dress4,
    name: "Casual Dress 4",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Dresses",
    size: ["S", "L", "XL"],
    color: ["yellow", "blue", "orange"],
    gender: "female",
    price: 185,
  },
  {
    id: "29",
    img: dress5,
    name: "Casual Dress 5",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Dresses",
    size: ["S", "L", "XL"],
    color: ["yellow", "orange"],
    gender: "female",
    price: 555,
  },
  {
    id: "30",
    img: dress6,
    name: "Casual Dress 6",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Dresses",
    size: ["S", "M"],
    color: ["yellow", "blue", "black"],
    gender: "female",
    price: 345,
  },
  {
    id: "31",
    img: dress7,
    name: "Casual Dress 7",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Dresses",
    size: ["S", "M"],
    color: ["orange", "blue", "black"],
    gender: "female",
    price: 345,
  },
  {
    id: "32",
    img: dress8,
    name: "Casual Dress 8",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Dresses",
    size: ["M", "L"],
    color: ["brown", "blue", "red"],
    gender: "female",
    price: 685,
  },
  {
    id: "33",
    img: jeans1,
    name: "Casual Jeans 1",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jeans",
    size: ["S", "M", "L"],
    color: ["blue", "black"],
    gender: "male",
    price: 55,
  },
  {
    id: "34",
    img: jeans2,
    name: "Casual Jeans 2",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jeans",
    size: ["S", "M"],
    color: ["blue", "black"],
    gender: "female",
    price: 75,
  },
  {
    id: "35",
    img: jeans3,
    name: "Casual Jeans 3",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jeans",
    size: ["M", "L", "XL"],
    color: ["blue", "black"],
    gender: "male",
    price: 95,
  },
  {
    id: "36",
    img: jeans4,
    name: "Casual Jeans 4",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jeans",
    size: ["M", "L", "XL"],
    color: ["blue", "black"],
    gender: "female",
    price: 100,
  },
  {
    id: "37",
    img: jeans5,
    name: "Casual Jeans 5",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jeans",
    size: ["M", "L", "XL"],
    color: ["blue", "black"],
    gender: "female",
    price: 200,
  },
  {
    id: "37",
    img: jeans6,
    name: "Casual Jeans 6",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jeans",
    size: ["S", "L", "XL"],
    color: ["blue", "black"],
    gender: "female",
    price: 200,
  },
  {
    id: "38",
    img: jeans7,
    name: "Casual Jeans 7",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jeans",
    size: ["M", "L", "XL"],
    color: ["blue", "black"],
    gender: "male",
    price: 180,
  },
  {
    id: "39",
    img: jeans8,
    name: "Casual Jeans 8",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jeans",
    size: ["M", "L", "XL"],
    color: ["blue", "black"],
    gender: "male",
    price: 160,
  },
  {
    id: "40",
    img: jacket1,
    name: "Casual Jacket 1",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jackets",
    size: ["M", "L", "XL"],
    color: ["black", "blue"],
    gender: "male",
    price: 400,
  },
  {
    id: "41",
    img: jacket2,
    name: "Casual Jacket 2",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jackets",
    size: ["S", "L", "XL"],
    color: ["gray", "brown"],
    gender: "male",
    price: 255,
  },
  {
    id: "42",
    img: jacket3,
    name: "Casual Jacket 3",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jackets",
    size: ["S", "M"],
    color: ["gray", "brown"],
    gender: "male",
    price: 300,
  },
  {
    id: "43",
    img: jacket4,
    name: "Casual Jacket 4",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jackets",
    size: ["S", "M", "L"],
    color: ["gray", "brown"],
    gender: "male",
    price: 185,
  },
  {
    id: "44",
    img: jacket5,
    name: "Casual Jacket 5",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jackets",
    size: ["S", "M", "L"],
    color: ["gray", "brown"],
    gender: "female",
    price: 185,
  },
  {
    id: "45",
    img: jacket6,
    name: "Casual Jacket 6",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jackets",
    size: ["S", "M", "XL"],
    color: ["gray", "brown", "black"],
    gender: "male",
    price: 285,
  },
  {
    id: "46",
    img: jacket7,
    name: "Casual Jacket 7",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jackets",
    size: ["S", "M", "XL"],
    color: ["brown", "black"],
    gender: "female",
    price: 425,
  },
  {
    id: "47",
    img: jacket8,
    name: "Casual Jacket 8",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Jackets",
    size: ["M", "L", "XL"],
    color: ["yellow", "orange", "blue"],
    gender: "female",
    price: 335,
  },
  {
    id: "48",
    img: bag1,
    name: "Casual Bag 1",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Bags",
    color: ["black", "blue", "red"],
    gender: "female",
    price: 500,
  },
  {
    id: "49",
    img: bag2,
    name: "Casual Bag 2",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Bags",
    color: ["green", "red", "blue"],
    gender: "male",
    price: 85,
  },
  {
    id: "50",
    img: bag3,
    name: "Casual Bag 3",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Bags",
    color: ["brown", "blue", "orange"],
    gender: "female",
    price: 200,
  },
  {
    id: "51",
    img: bag4,
    name: "Casual Bag 4",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Bags",
    color: ["brown", "blue", "orange"],
    gender: "female",
    price: 25,
  },
  {
    id: "52",
    img: bag5,
    name: "Casual Bag 5",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Bags",
    color: ["yellow", "blue", "black"],
    gender: "female",
    price: 40,
  },
  {
    id: "53",
    img: bag6,
    name: "Casual Bag 6",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Bags",
    color: ["blue", "black", "orange"],
    gender: "female",
    price: 400,
  },
  {
    id: "54",
    img: bag7,
    name: "Casual Bag 7",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Bags",
    color: ["blue", "black", "orange"],
    gender: "female",
    price: 355,
  },
  {
    id: "55",
    img: bag8,
    name: "Casual Bag 8",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Bags",
    color: ["blue", "green", "red"],
    gender: "female",
    price: 100,
  },
  {
    id: "56",
    img: suit1,
    name: "Casual Suit 1",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Suits",
    size: ["S", "M", "L"],
    color: ["black", "gray", "orange"],
    gender: "male",
    price: 300,
  },
  {
    id: "57",
    img: suit2,
    name: "Casual Suit 2",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Suits",
    size: ["S", "M", "L"],
    color: ["purple", "orange", "gray"],
    gender: "male",
    price: 400,
  },
  {
    id: "58",
    img: suit3,
    name: "Casual Suit 3",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Suits",
    size: ["M", "L", "XL"],
    color: ["purple", "orange", "gray"],
    gender: "male",
    price: 200,
  },
  {
    id: "59",
    img: suit4,
    name: "Casual Suit 4",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Suits",
    size: ["L", "XL"],
    color: ["brown", "orange", "black"],
    gender: "male",
    price: 600,
  },
  {
    id: "60",
    img: suit5,
    name: "Casual Suit 5",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Suits",
    size: ["L", "XL"],
    color: ["black", "blue", "red"],
    gender: "male",
    price: 750,
  },
  {
    id: "61",
    img: suit6,
    name: "Casual Suit 6",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Suits",
    size: ["S", "M"],
    color: ["brown", "orange", "black"],
    gender: "female",
    price: 385,
  },
  {
    id: "62",
    img: suit7,
    name: "Casual Suit 7",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Suits",
    size: ["L", "XL"],
    color: ["red", "blue", "black"],
    gender: "male",
    price: 700,
  },
  {
    id: "63",
    img: suit8,
    name: "Casual Suit 8",
    text: "Fashion never stops. There is always the new project, the new opportunity. The important thing is to take your time and not get stressed. I just want to do what I do.",
    type: "Suits",
    size: ["S", "M", "XL"],
    color: ["blue", "green", "brown"],
    gender: "male",
    price: 550,
  },
];
